<template>
  <div
    class="modal fade show"
    id="add-to-calendar-modal"
    tabindex="-1"
    aria-labelledby="add-to-calendar-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content add-to-calendar-modal-content">
        <div class="modal-header add-to-calendar-modal-header">
          <h3 class="add-to-calendar-modal-title">Add to calendar</h3>
          <button class="close-modal-btn" size="md" @click="hide">×</button>
        </div>
        <div class="modal-body">
          <ul class="list-group list-group-flush" @click="select">
            <li
              v-for="item in options"
              :key="item.title"
              class="list-group-item"
              :data-title="item.title"
            >
              <i class="fa-brands" :class="[item.icon]" :data-title="item.title"></i>
              <span :data-title="item.title">{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// import { useUserStore } from '@/store/modules/user';
import * as bootstrap from 'bootstrap';
import dayjs from 'dayjs';
import toArray from 'dayjs/plugin/toArray';
import { ref, onMounted, watch, computed } from 'vue';
// import * as ics from 'ics';
import { createEvent, DateArray, EventAttributes } from 'ics';
import { saveAs } from 'file-saver';
import { useUserStore } from '@/store/modules/user';

dayjs.extend(toArray);
// const props = defineProps(['isVisible', 'schedule']);
// const emits = defineEmits(['close']);
// const userStore = useUserStore();

const myModal: any = ref(null);

const userStore = useUserStore();

const calendarStatus: any = computed(() => {
  return userStore.getCalendarStatus;
});

const formatString = (string) => {
  return encodeURIComponent(string).replace(/%20/g, '+');
}

const formatDate = (date, needReplace = false) => {
  if (date) {
    if (needReplace) {
      return new Date(date).toISOString().replace(/-|:|\.\d+/g, '');
    }
    return new Date(date).toISOString()
  }
  return null
}

const calendars = {
  google: {
    url: 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false',
    parameters (title, location, details, start, end) {
      const parameters: any = {
        text: title,
        location: location,
        details: details
      };

      if (start && end) {
        parameters.dates = `${start}/${end}`;
      }

      return parameters;
    }
  },

  microsoft: {
    url: 'https://outlook.live.com/owa/?rru=addevent',
    parameters (title, location, details, start, end) {
      return {
        subject: title,
        location: location,
        body: details,
        startdt: start,
        enddt: end
      };
    }
  },

  office365: {
    url: 'https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent',
    parameters (title, location, details, start, end) {
      return {
        subject: title,
        location: location,
        body: details,
        startdt: start,
        enddt: end
      };
    }
  }
};

const calendarUrl = (calendar, schedule) => {
  let url = calendars[calendar].url;
  const needReplace = ['google'].includes(calendar);
  const parameters = calendars[calendar].parameters(
    formatString(schedule.title),
    formatString(schedule.location),
    formatString(schedule.details),
    formatDate(schedule.start, needReplace),
    formatDate(schedule.end, needReplace)
  );

  for (const key in parameters) {
    if (parameters.hasOwnProperty(key) && parameters[key]) {
      url += `&${key}=${parameters[key]}`;
    }
  }

  return url;
}

const options = computed(() => {
  const { schedule: scheduleDetail } = calendarStatus.value;
  if (scheduleDetail && scheduleDetail.startedAt) {
    // console.log('scheduleDetail', scheduleDetail);
    
    const { workout, startedAt, duration, videoLink } = scheduleDetail;

    const schedule = {
      title: workout.title,
      location: 'Upotential - The Gym for Mental Fitness',
      details: `Zoom link: ${videoLink}`,
      start: startedAt,
      end: dayjs(startedAt).add(duration, 'second'),
    }
    const _options = [
      {
        // http://www.google.com/calendar/render?action=TEMPLATE&trp=false&text=Vividness&location=Upotential+-+the+gym+for+mental+fitness&details=Zoom+link%3A+https%3A%2F%2Fus02web.zoom.us%2Fj%2F84743357609&dates=20220628T173000Z/20220628T174500Z
        title: 'Google Calendar',
        icon: 'fa-google',
        // link: `https://calendar.google.com/calendar/u/0/r/eventedit?trp=false&text=${ props.schedule.workout?.title }&location=Upotential+-+the+gym+for+mental+fitness&details=Zoom+link:+${ props.schedule.videoLink }&dates=20220531T053000Z/20220531T063000Z`
        link: calendarUrl('google', schedule)
      },
      {
        // https://outlook.live.com/owa/?rru=addevent&subject=Vividness&location=Upotential+-+the+gym+for+mental+fitness&body=Zoom+link%3A+https://us02web.zoom.us/j/84743357609&startdt=2022-06-28T17:30:00.000Z&enddt=2022-06-28T17:45:00.000Z
        title: 'Microsoft/Live Calendar',
        icon: 'fa-microsoft',
        // link: `https://outlook.live.com/owa/?rru=addevent&subject=${ props.schedule.workout?.title }&location=Upotential+-+the+gym+for+mental+fitness&body=Zoom+link%3A+${ props.schedule.videoLink }&startdt=2022-05-31T05:30:00.000Z&enddt=2022-05-31T06:30:00.000Z`
        link: calendarUrl('microsoft', schedule)
      },
      {
        // https://outlook.office.com/owa/?path=/calendar/action/compose&rru=addevent&subject=Vividness&location=Upotential+-+the+gym+for+mental+fitness&body=Zoom+link%3A+https://us02web.zoom.us/j/84743357609&startdt=2022-06-28T17:30:00.000Z&enddt=2022-06-28T17:45:00.000Z
        title: 'Outlook/Office 365 Calendar',
        icon: 'fa-microsoft',
        // link: 'https://login.microsoftonline.com/common/oauth2/authorize?client_id=00000002-0000-0ff1-ce00-000000000000&redirect_uri=https%3a%2f%2foutlook.office.com%2fowa%2f&resource=00000002-0000-0ff1-ce00-000000000000&response_mode=form_post&response_type=code+id_token&scope=openid&msafed=1&msaredir=1&client-request-id=97b45497-d7a5-6415-6fff-9ad45d489134&protectedtoken=true&claims=%7b%22id_token%22%3a%7b%22xms_cc%22%3a%7b%22values%22%3a%5b%22CP1%22%5d%7d%7d%7d&nonce=637896733733972964.245710de-d9b8-4c44-85b0-99362cfb4dc3&state=XY9NboMwEIUhPUvYWMYTGwxGQlVP0E27yc5_CaSAkW0S0X3vXVfdVJXmzRvpmzfS5FmWPSUdknJILWs4a1rBG8ZSiYYKXpW0qpsTGIuNUC2udFXhtlaAhWCc6ouqjGZ5yn4diHtI8rzKOPREy8kuRnoidRzdQrSbVxds4f3WS2Ps3S6xCJu6WR3717v1acEeKexu88nmhOWUBiW9H60PxeS0_LnUv68uJjrKCWEUB4uu-4wuzqPfDLqMcbEhFMqZvT87N6NpXD6O7AUNMa6hI2QLQB9WlZ8JllsgN9I2oub1CQSnRYjSRxN7CpRiqDE7vUHdMegASgA4F-mvf5j_wd8'
        link: calendarUrl('office365', schedule)
      },
      {
        title: 'Apple iCloud/iCal',
        icon: 'fa-apple',
        link: ''
      },
    ];
    return _options;
  }
  return []
});

// watch(
//   () => props.isVisible,
//   (value: any) => {
//     if (value) {
//       show();
//     } else {
//       hide();
//     }
//   },
//   {
//     deep: true,
//   }
// );

watch(
  () => calendarStatus.value,
  (value: any) => {
    if (value.show) {
      show();
    } else {
      hide();
    }
  },
  {
    deep: true,
  }
);

const show = () => {
  myModal.value.show();
};
const hide = () => {
  myModal.value.hide();
};

const createICSEvent = (event, filename) => {
  const { error, value } = createEvent(event);

  if (error) {
    console.log(error)
    return
  }

  const Calendar = addCRLF(value);
  const blob = new Blob([Calendar], {type: "text/x-vCalendar;charset=utf-8"});
  // this is to target mainly iOS and iPadOS devices where this will open the calendar app
  if("ontouchend" in document){
    window.location.href = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(Calendar);
  }else{
    // for everything else, use saveAs so we can specify the filename
    saveAs(blob, `${filename}.ics`);
  }
}

const addCRLF = (string) => {
  return `${string}`
}
const download = () => {
  const { workout, startedAt, duration, videoLink } = calendarStatus.value.schedule;
  const start = dayjs(startedAt).toArray().slice(0, 5) as DateArray;
  start[1] = start[1] + 1;
  const event: EventAttributes = {
    start,
    duration: { seconds: duration },
    title: workout.title,
    description: workout.title,
    location: 'Upotential - The Gym for Mental Fitness',
    url: videoLink,
    // geo: { lat: 40.0095, lon: 105.2669 },
    // categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
    // status: 'CONFIRMED',
    // busyStatus: 'BUSY',
    // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
    // attendees: [
    //   { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
    //   { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
    // ]
  }

  createICSEvent(event, workout.title);
}

const select = (e) => {
  const { title } = e.target.dataset;
  if (title === 'Apple iCloud/iCal') {
    download();
  } else {
    const item = options.value.find(item => item.title === title);
    window.open(item?.link, '_blank');
  }
};

onMounted(() => {
  const el = document.getElementById('add-to-calendar-modal');
  myModal.value = new bootstrap.Modal(el as HTMLElement);

  el?.addEventListener('hidden.bs.modal', function () {
    console.log('[hidden.bs.modal]');
    // emits('close');
    userStore.setCalendarStatus({
      show: false,
      cancelFn: null
    });
    // let callback = responseStatus.value.hideCallback;
    // userStore.setResponseStatus({
    //   status: true,
    //   msg: 'This is default response message',
    //   show: false,
    //   size: '',
    //   hideCallback: null
    // });

    // if (callback && typeof callback === 'function') {
    //   callback();
    // }
  });
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  width: 396px;
}
.modal-body {
  padding: 8px 16px 16px;
  letter-spacing: -0.02em;
  .list-group-item {
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    cursor: pointer;
    i {
      color: #00219b;
      margin-right: 8px;
      font-size: 24px;
    }
    &:hover {
      font-weight: bold;
      i {
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.4));
      }
    }
  }
}
.add-to-calendar-modal-content {
  border-radius: 0;
  border: none;
  padding: 1.2vw;
  font-family: SharpGrotesk;
  font-size: 16px;
  // font-size: clamp(20px, 1.5vw, 26px);
}

.add-to-calendar-modal-content {
  background-color: #e9f3fc;
  padding: 0;
}

.add-to-calendar-modal-header {
  height: 56px;
  background: rgba(32, 129, 219, 0.2);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-family: SharpGroteskSm;
  font-weight: bold;
  border-bottom: none;
  text-align: center;
}
.add-to-calendar-modal-title {
  width: 100%;
  font-family: SharpGroteskSm;
  font-weight: bold;
  border-bottom: none;
  font-size: 20px;
  // font-size: clamp(20px, 2vw, 40px);
  color: #293e92;
  margin-bottom: 0;
  text-align: center;
}
.close-modal-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: #00219e;
  border: none;
  color: #fff;
  font-size: 25px;
  // padding: 5px 15px;
  position: absolute;
  right: -16px;
  top: -16px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .modal-dialog{
    width: 90%;
    margin-left: 5%;
  }
}


// .close-modal-btn:hover {
//   background-color: transparent;
//   border: none;
//   color: #00219c;
// }
</style>
