<template>
  <div v-if="!hide">
    <section class="cta cta-5">
    </section>

    <section class="footer">
      <div class="footer-logo">
        <img src="~@/assets/images/footer-logo-horizontal-blue.svg" />
        <p>
          © 2023 Upotential AB<br />
          Östermalmsgatan 26A, 114 26 Stockholm, Sweden
        </p>
      </div>
      <div class="footer-links">
        <div class="social-icons">
          <a href="mailto:hello@upotential.se" target="_blank">
            <i class="fa-solid fa-envelope"></i>
          </a>
          <a href="https://www.instagram.com/upotential.se/" target="_blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/upotential/"
            target="_blank"
          >
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
          <a
            href="https://www.tiktok.com/@upotential"
            target="_blank"
          >
            <i class="fa-brands fa-tiktok"></i>
          </a>
        </div>
        <ul class="extra-links">
          <li>
            <a href="mailto:hello@upotential.se">Contact us</a>
          </li>
          <li>
            <a href="/about-us">About us</a>
          </li>
          <li>
            <a href="/FAQ">FAQ</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-of-service">Terms of Service</a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const hide = computed(() => {
  const hideViews = [
    'quiz',
  ]

  if (hideViews.includes(route.name as string)) {
    return true;
  } else {
    return false;
  }
});

</script>

<style lang="scss" scoped>

.cta-5{
  height: 100px;
}

section.footer {
  display: flex;
  background-color: #65e4c4;
  justify-content: space-around;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 30px;

  .footer-logo img {
    width: 300px;
  }

  .footer-links {
    p {
      margin: 10px;
      font-size: 14px;
    }

    flex-basis: 300px;
    text-align: center;
  }

  .footer-logo p {
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
  }

  .social-icons {
    text-align: center;
  }

  .social-icons a {
    font-size: 26px;
    background-color: #00219b;
    color: #65e4c4;
    text-align: center;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    transition: 0.25s all ease;
    margin: 6px;
  }

  .extra-links {
    text-align: center;
    font-size: 14px;
    padding-left: 0px;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 8px;
    }

    a {
      color: #00219b;
      cursor: pointer;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .footer-links a:hover {
    filter: brightness(1.25);
  }
}

@media (max-width: 800px) {
  section.footer {
    flex-direction: column-reverse;
  }

  .footer-links{
    flex-basis: 100px !important;
    order: 2;
  }

  .footer-logo {
    order: 3;

  }

  .social-icons {
    display: none;
  }

  .extra-links {
    margin-bottom: 0;
  }
}
</style>
