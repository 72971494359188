<template>
  <svg
    aria-hidden="true"
    class="svg-icon"
    :class="{[`${props.prefix}-${props.name}`]: true}"
    :width="size[0]"
    :height="size[1]"
  >
    <use :xlink:href="symbolId" rel="external nofollow" :fill="props.color" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
const props = defineProps({
  prefix: {
    type: String,
    default: 'icon',
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '#333',
  },
  size: {
    type: String,
    default: '1em',
  },
});
const symbolId = computed(() => `#${props.prefix}-${props.name}`);
const size = computed(() => {
  const sizeArr = props.size.split(',');
  return sizeArr.length > 1 ? sizeArr : [sizeArr[0], sizeArr[0]]
});
</script>
<style lang="scss" scoped>
.svg-icon {
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
}
</style>
