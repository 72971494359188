<template>
  <div
    id="confirm-modal"
    class="modal fade show"
    tabindex="-1"
    aria-labelledby="confirm-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content confirm-modal-content">
        <div class="modal-header confirm-modal-header">
          <button class="close-modal-btn" size="md" @click="hide">×</button>
        </div>
        <div class="modal-body">
          <h3 class="confirm-modal-title">{{ comfirmStatus?.title }}</h3>
          <div class="modal-body-content">{{ comfirmStatus?.msg }}</div>
          <div class="confirm-modal-actions">
            <button
              v-if="!comfirmStatus.hideOk && okText === 'Buy membership'"
              class="btn btn-large-primary"
              @click="ok"
            >
              {{ okText }}
            </button>
            <button class="btn btn-large-primary" v-else-if="!comfirmStatus.hideOk" href="javascript:;" @click="ok">{{ okText }}</button>
            <button class="cancel-btn btn btn-large-primary" v-if="!comfirmStatus.hideCancel" href="javascript:;" @click="hide">{{ cancelText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/modules/user';
import * as bootstrap from 'bootstrap';
import { ref, onMounted, watch, computed, nextTick } from 'vue';

// const emits = defineEmits(['close', 'ok']);
const myModal: any = ref(null);

const userStore = useUserStore();

const comfirmStatus: any = computed(() => {
  return userStore.getComfirmStatus;
});

const okText = computed(() => {
  return comfirmStatus.value.okText ?? 'Yes';
});
const cancelText = computed(() => {
  return comfirmStatus.value.cancelText ?? 'No';
});

watch(
  () => comfirmStatus.value,
  (value: any) => {
    if (value.show) {
      show();
    } else {
      hide();
    }
  },
  {
    deep: true,
  }
);

const show = () => {
  myModal.value.show();
};
const hide = () => {
  if (comfirmStatus.value.show) {
    myModal.value.hide();
  }
};

const ok = async () => {
  await comfirmStatus.value.okFn();
  hide();
}

onMounted(async () => {
  await nextTick();
  const el = document.getElementById(`confirm-modal`);
  myModal.value = new bootstrap.Modal(el as HTMLElement);

  el?.addEventListener('hidden.bs.modal', function () {
    console.log('[hidden.bs.modal]');
    userStore.setComfirmStatus({
      msg: 'This is default response message',
      show: false,
      size: '',
      okFn: null,
      cancelFn: null
    });
  });
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  width: 396px;
}
.modal-body {
  padding: 0px 32px 32px;
  letter-spacing: -0.02em;
  .modal-body-content {
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-top: 8px;
  }
  .confirm-modal-actions {
    margin-top: 16px;
    padding: 8px 0;
    display: flex;
    justify-content: space-around;
    button {
      font-family: SharpGroteskSm;
      text-decoration: none;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      &.cancel-btn {
        // color: #CA2F15;
        background-color: transparent !important;
        border: 2px solid black;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: #0002 !important;
          // color: #fff;
          box-shadow: none;
          border-width: 3px;
        }
      }
    }
  }
}
.confirm-modal-content {
  border-radius: 0;
  border: none;
  padding: 1.2vw;
  font-family: SharpGrotesk;
  font-size: 16px;
  background-color: #ffdacf;
  padding: 0;
}

.confirm-modal-header {
  height: 32px;
  border-bottom: unset;
}
.confirm-modal-title {
  width: 100%;
  font-family: SharpGroteskSm;
  font-weight: bold;
  border-bottom: none;
  font-size: 20px;
  // font-size: clamp(20px, 2vw, 40px);
  color: #293e92;
  margin-bottom: 0;
  // text-align: center;
}
.close-modal-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: #00219e;
  border: none;
  color: #fff;
  font-size: 25px;
  // padding: 5px 15px;
  position: absolute;
  right: -16px;
  top: -16px;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 90%;
    margin: 0 auto;
  }

  .confirm-modal-actions {
    button {
      min-width: 100px;
    }
  }
}
</style>
