import axios from 'axios';
import { storage } from '../storage';
import { useGlobSetting } from '@/hooks/setting';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { useUserStoreWidthOut } from '@/store/modules/user';

const globSetting = useGlobSetting();
const urlPrefix = globSetting.urlPrefix || '';
const apiUrl = globSetting.apiUrl || '';

const baseURL = apiUrl ? `${apiUrl}/${urlPrefix}` : urlPrefix;

//create axios instance
const instance = axios.create({
  baseURL,
  timeout: 6000,
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
    // 'content-type': 'application/x-www-form-urlencoded;charset:utf-8;',
    // 'X-CSRFTOKEN': storage.getCookie(CSRF_TOKEN)
    // 'x-csrftoken': storage.getCookie(CSRF_TOKEN) || 'null'
  },
});
// instance.defaults.headers.post['content-type'] = 'application/json';
// let loading: any;
//正在请求的数量
// let requestCount = 0;
//show loading
// const showLoading = () => {
//   requestCount++;
// };
// //hide loading
// const hideLoading = () => {
//   requestCount--;
//   if (requestCount == 0) {
//     // loading.close();
//   }
// };

// request interceptors
instance.interceptors.request.use(
  (config) => {
    // console.log('config.headers', config.headers);
    
    // config.headers = {'content-type':'application/json'}
    // showLoading();
    // const token = window.localStorage.getItem('token');
    const token = storage.get(ACCESS_TOKEN);
    (token && config.headers) && (config.headers.Authorization = `Bearer ${token}`);

    // console.log((config.method?.toLocaleLowerCase() === 'post'));
    if (config.method?.toLocaleLowerCase() === 'post') {
      config.data = JSON.stringify(config.data);
      // config.data = qs.stringify(config.data, {arrayFormat: 'brackets'});
    }
    return config;
  },
  (error) =>
    Promise.reject(error)
);

// response interceptors
instance.interceptors.response.use(
  (response) => {
    // console.log('[instance.interceptors.response]', response);
    
    // hideLoading();
    return response.data;
  },
  (error) => {
    const userStore = useUserStoreWidthOut();
    // console.log('[error]', error);
    const { response } = error
    console.log('[error-response]', response);
    const { status: code, statusText: message, data } = response

    if (code === 401) {
      userStore.logout(true)
      return Promise.reject(data.message)
    }

    if (data && data.message) {
      let rS = {
        status: 'Failed',
        msg: data.message,
        show: true,
      };

      if (code === 400 && data.details) {
        const _message = Object.values(data.details).reduce((preText, curText) => {
          return `${preText}; ${curText}`;
        });
        rS.msg = _message;
      }
      userStore.setResponseStatus(rS);
      // return;
      return Promise.reject(data.message)
    }
    // Network request error
    if (response && code) {
      const status = error.response.status;
      let _message: string;
      switch (status) {
        case 400:
          _message = 'Network request error';
          break;
        case 401:
          _message = 'The user does not have permission (wrong token, user name and password)!';
          break;
        case 403:
          _message = 'The user is authorized, but access is prohibited.!';
          break;
        case 404:
          _message = 'Network request error, the resource was not found!';
          break;
        case 405:
          _message = 'Network request error, request method not allowed!';
          break;
        case 408:
          _message = 'Network request timeout';
        case 409:
          _message = 'A user is already registered with this e-mail address.';
          break;
        case 500:
          _message = 'Server error, please contact the administrator!';
          break;
        case 501:
          _message = 'Network not implemented';
          break;
        case 502:
          _message = 'network error';
          break;
        case 503:
          _message = 'Service unavailable, server temporarily overloaded or maintained!';
          break;
        case 504:
          _message = 'Network Timeout';
          break;
        case 505:
          _message = 'The HTTP version does not support this request!';
          break;
        default:
          _message = 'Network request error';
      }
      console.log('[message]', _message);
      
      // ElMessage.error(message);
      return Promise.reject(message);
      // return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
