import type { App } from 'vue';
import svgIcon from '@/components/SvgIcon.vue';

/**
 * 全局注册自定义组件 待完善
 * @param app
 */
export function setupCustomComponents(app: App) {
  app.component('svg-icon', svgIcon);
}
