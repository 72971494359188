<template>
  <div
    v-if="(typeof allowAnalytics !== 'boolean')"
    class="cnotice"
  >
    <h4>Cookie Notice</h4>
    <p>We use some necessary cookies to make the Upotential site function.</p>
    <p>
      We’d like to set some additional, optional cookies to understand how you
      use our services.
    </p>
    <div class="options">
      <button @click="accept(true)" class="all">
        <strong>Accept all</strong>
      </button>
      <button @click="accept(false)" class="necessary">
        Accept necessary
      </button>
      <a target="_blank" href="/cookies" class="info">More info</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { bootstrap } from 'vue-gtag';
import { useUserStore } from '@/store/modules/user';

const allowAnalytics = ref(null);
const userStore = useUserStore();

const initAnalytics = () => {
  bootstrap().then((gtag) => {
    // all done!
    console.log(gtag);
  });
}
const accept = (all) => {
  userStore.setAllowAnalytics(all);

  allowAnalytics.value = all;

  if (all) {
    initAnalytics();
  }
}
onMounted(() => {
  console.log('userStore.getAllowAnalytics', userStore.getAllowAnalytics);
  
  allowAnalytics.value = userStore.getAllowAnalytics;

  if (allowAnalytics.value) {
    initAnalytics();
  }
});
</script>

<style lang="scss" scoped>
.cnotice {
  max-width: 100%;
  width: 500px;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 10000;
  padding: 20px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 15px 0 0 0;
}

button,
a {
  color: black;
  border: 0;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
}

a {
  display: inline-block;
}

.all {
  background-color: #fcc105;
  margin-left: 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.info {
  background-color: transparent;
}

@media (max-width: 500px) {
  .cnotice {
    border-radius: 0;
  }
}
</style>
