<template>
  <div class="app-container" v-cloak>
    <NavbarComponent2 v-if="showNavbar" />
    <CookieNotice />
    <!-- <RegisterModal />
    <PasswordResetModal />
    <BookModal />
    -->
    <ResponseModal />
    <ConfirmModal />
    <AddToCalendarModal />
    <!-- <WelcomePopup /> -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :key='$route.fullPath' v-if="route.meta.keepAlive" :is="Component" />
      </keep-alive>
      <component :key='$route.fullPath' v-if="!route.meta.keepAlive" :is="Component" />
    </router-view>
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="route.meta.keepAlive" :key="route.name"></component>
      </keep-alive>
      <component :is="Component" v-if="!route.meta.keepAlive" :key="route.name">></component>
    </router-view> -->

  </div>
  <FooterMini v-if="showMiniFooter" />
</template>

<script lang="ts" setup>
import NavbarComponent2 from '@/components/Navbar2.vue';
import CookieNotice from '@/components/CookieNotice.vue';
// import BookModal from '@/components/BookWorkoutModal.vue';
import ResponseModal from '@/components/ResponseModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import AddToCalendarModal from '@/components/AddToCalendarModal.vue';
import FooterMini from '@/components/FooterMini.vue';
// import WelcomePopup from '@/components/WelcomePopup.vue';
// import RegisterModal from '@/components/RegisterModal.vue';
// import PasswordResetModal from '@/components/PasswordResetModal.vue';
import { computed, onMounted, ref } from 'vue';
import { useUserStore } from './store/modules/user';
import { useRoute } from 'vue-router';
import { fetchSchedulesRecommended } from './common/http/api';

const userStore = useUserStore();
const route = useRoute();

const showNavbar = computed(() => {
  const filterName: string[] = [];
  const hasRoute = filterName.includes(route.name as string);
  if (hasRoute && !userStore.getAuthenticated) {
    return false;
  }
  return true;
});

const showMiniFooter = computed(() => {

  const excludedRoutes = [
    'rate-workout',
  ]

  if (excludedRoutes.includes(route.name as string)) {
    return false;
  }

  if (!userStore.getAuthenticated) {
    return false;
  }
  return true;
});

const scrollPosition: any = ref(null);
const updateScroll = () => {
  scrollPosition.value = window.scrollY;
};

const fetchSchedules = async () => {
  const params = {
    introduction: true,
    size: 1,
  };
  const { data } = await fetchSchedulesRecommended(params);
  if (data.length) {
    const firstId = data[0].id;
    userStore.setFirstSchedulesRecommendedId(firstId);
  } else {
    userStore.setFirstSchedulesRecommendedId(0);
  }
};

onMounted(async () => {
  await fetchSchedules();
  window.addEventListener('scroll', updateScroll);

  let uri = window.location.search.substring(1);
  const code = new URLSearchParams(uri).get('code');

  if (code) {
    window.sessionStorage.setItem('signupCode', code);
  }
});


</script>

<style lang="scss">
@import url('./common/main.scss');

.levitate {
  transform: translateY(0);
  transition: all 0.3s;
  box-shadow: #00000011 0px 0px 1px 0px;
  cursor: pointer;
}

.levitate:hover {
  transform: translateY(-3px);
  box-shadow: #00000022 0px 3px 3px 0px !important;
}

.app-container {
  min-height: calc(100vh - 300px);
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 100px;
}

@media (max-width: 887px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
    padding: 0 7vw;
  }
}

@media (min-width: 888px) and (max-width: 1079px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 884px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1080px) and (max-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1104px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1201px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1104px;
  }
}

@font-face {
  font-family: 'SharpGrotesk';
  src: url(/fonts/SharpGroteskBook20.woff) format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'SharpGroteskSm';
  src: url(/fonts/SharpGroteskBook25.woff) format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'SharpGrotesk';
  src: url(/fonts/SharpGroteskSmBold20.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'SharpGroteskSm';
  src: url(/fonts/SharpGroteskSmBold25.woff) format('woff');
  font-weight: bold;
}

#app {
  font-family: SharpGrotesk, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  width: 100%;
  min-height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

button.up-button {
  font-family: 'SharpGroteskSm';
  font-size: 16px;
  padding: 9px 50px;
  border: none;
  background-color: #00219b;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.2s all;
  box-shadow: #00219b 0px 0px 0px;
  transform: translateY(0);
}

button.up-button:hover {
  filter: brightness(120%);
  box-shadow: #00219b 0px 2px 4px;
  transform: translateY(-2px);
}

.subsection-header {
  font-family: SharpGroteskSm;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 30px;
}

h3 {
  font-weight: bold;
}

html {
    scroll-behavior: auto !important;
}

body {
  font-family: SharpGrotesk, Avenir, Helvetica, Arial, sans-serif;
  background-color: #ffffff;
  min-height: 100%;
}

[v-cloak] {
  display: none;
}

ul,
li {
  list-style: none;
}

.callout {
  background-color: #eee;
  padding: 20px;
  border-radius: 6px;

  &.success {
    background-color: #c4f5e9;
  }

  &.warning {
    background-color: #ffe3db;
  }
}

.pt-64 {
  padding-top: 64px;
}

.pt-70 {
  padding-top: 70px;
}

.safety-top-padding {
  padding-top: 64px;
}

.detail-tags {
  display: flex;
  flex-wrap: wrap;

  .tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: #00219e;
    color: #fff;
    font-family: SharpGrotesk;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    padding: 0px 12px 0px 10px;
    border-radius: 36px;
    margin: 12px 10px 0 0;

    .icon-detail-type {
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 5px;

      &.icon-type-CC {
        background-color: #fff;
      }
    }
  }
}

.MS-type,
.MF-type,
.ME-type,
.CC-type,
.GT-type,
.IN-type {
  .header-title {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 120%;
  }

  header {
    img {
      border-radius: 50%;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .workouts-item--time {
    .svg-icon {
      border-radius: 50%;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.MS-type {
  background-color: rgba(63, 225, 184, 0.1);

  // .header-block {
  //   &:hover {
  //     background-color: rgba(63, 225, 184, 0.2);
  //   }
  // }
  .text-color {
    color: #00553f;
  }

  .card-header.header-block {
    background-color: rgba(63, 225, 184, 0.2);
  }

  .tag {
    background-color: rgba(63, 225, 184, 0.15);
  }
}

.MF-type {
  background-color: rgba(255, 70, 17, 0.1);

  // .header-block {
  //   &:hover {
  //     background-color: rgba(255, 70, 17, 0.2);
  //   }
  // }
  .text-color {
    color: #ca2f15;
  }

  .card-header.header-block {
    background-color: rgba(255, 70, 17, 0.2);
  }

  .tag {
    background-color: rgba(255, 70, 17, 0.1);
  }
}

.ME-type {
  background-color: rgba(32, 129, 219, 0.1);

  // .header-block {
  //   &:hover {
  //     background-color: rgba(32, 129, 219, 0.2);
  //   }
  // }
  .text-color {
    color: #293e92;
  }

  .card-header.header-block {
    background-color: rgba(32, 129, 219, 0.2);
  }

  .tag {
    background-color: rgba(32, 129, 219, 0.1);
  }
}

.CC-type {
  background-color: #ffd858;

  // .header-block {
  //   &:hover {
  //     background-color: rgba(255, 201, 24, 1);
  //   }
  // }
  .text-color {
    color: #000000;
  }

  .card-header.header-block {
    background-color: #ffc918;
  }

  .tag {
    background-color: #ffc91822;
  }

  .svg-icon {
    border-radius: 50%;
  }

  .CC-Svg-Icon {
    background-color: #fff;
  }
}

.IN-type, .GT-type {
  background-color: #fff0c0;

  // .header-block {
  //   &:hover {
  //     background-color: rgba(255, 201, 24, 1);
  //   }
  // }
  .text-color {
    color: #000000;
  }

  .card-header.header-block {
    background-color: #fff0c0;
  }

  .tag {
    background-color: #ffc91855;
  }

  .svg-icon {
    border-radius: 50%;
  }

  .IN-Svg-Icon {
    background-color: #fff;
  }
}

.icon-type-MS,
.icon-type-MF,
.icon-type-ME,
.icon-type-CC,
.icon-type-GT,
.icon-type-IN {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-type-MS {
  background-color: rgba(56, 222, 168, 1);
}

.icon-type-MF {
  background-color: rgba(251, 46, 17, 1);
}

.icon-type-ME {
  background-color: rgba(26, 105, 209, 1);
}

.icon-type-CC {
  background-color: #f6ca49;
}

.icon-type-IN, .icon-type-GT {
  background-color: #0b11c6;
}

.content-wrapper {
  background-color: rgba(0, 33, 158, 0.9);
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  font-family: SharpGrotesk;
}

.tippy-box[data-theme~='tomato'][data-placement^='top']>.tippy-arrow::before {
  border-top-color: tomato;
}

.tippy-box[data-theme~='tomato'][data-placement^='bottom']>.tippy-arrow::before {
  border-bottom-color: tomato;
}

.tippy-box[data-theme~='tomato'][data-placement^='left']>.tippy-arrow::before {
  border-left-color: tomato;
}

.tippy-box[data-theme~='tomato'][data-placement^='right']>.tippy-arrow::before {
  border-right-color: tomato;
}

.header-actions {
  margin: 20px 0 10px;
}

.medium-note {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.small-note {
  font-size: 12px;
  font-weight: 500;
  color: #888;
  margin: 5px 0px;
}

textarea {
  resize: none;
}

.title-large {
  font-family: SharpGroteskSm;
  font-weight: 700;
  font-size: 61px;
  margin-bottom: 32px;
}

.public-layout {

  margin-bottom: 50px;

  h2 {
    font-family: SharpGroteskSm;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 32px;
  }

  .header {
    // margin-top: 70px;
    // background-image: url(assets/images/corporate-header.jpg);
    background-color: #eee;
    background-size: cover;
    background-position: center top;
    // height: 60vh;
    min-height: 500px;
    position: relative;
    padding-top: calc(env(safe-area-inset-top) + 70px);
    padding-bottom: 100px;

    display: flex;
    /*   flex-direction: column; */
    justify-content: center;

  }

  section {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .header-contents {

    color: white;
    background-position: center;

    h1 {
      margin-top: 30px;
      font-family: SharpGroteskSm;
      font-size: 70px;
      font-weight: bold;
      line-height: 100%;
    }

    p {
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 19px;
      max-width: 500px;
    }
  }

  .bottom-angle {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(181.5deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        white 50.3%,
        white 100%);
    width: 100%;
    height: 50px;
  }

}

@media (max-width: 1280px) {

  .public-layout section {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

}

@media (max-width: 767px) {
  .safety-top-padding {
    padding-top: 72px;
  }

  button.up-button {
    padding: 9px 22px;
  }

  .title-large {
    font-size: 41px;
  }

  .public-layout {
    .header-contents {

      h1 {
        font-size: 40px;
      }

      p {
        font-size: 18px;
      }

    }
  }
}

@media (max-width: 1179px) {
  .MS-type {
    .header-block {
      background-color: rgba(63, 225, 184, 0.2);
    }
  }

  .MF-type {
    .header-block {
      background-color: rgba(255, 70, 17, 0.2);
    }
  }

  .ME-type {
    .header-block {
      background-color: rgba(32, 129, 219, 0.2);
    }
  }

  .CC-type {
    .header-block {
      background-color: rgba(255, 201, 24, 1);
    }
  }

  .IN-type, .GT-type {
    .header-block {
      background-color: rgba(255, 201, 24, 1);
    }
  }
}
</style>
