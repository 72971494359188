// http/request.js
import instance from './index';
/**
 * @param {String} method  get、post、delete、put
 * @param {String} url
 * @param {Object} data
 * @param {Object} config
 * @returns {Promise}     returns promise
 */

const axios = ({
  method,
  url,
  data,
  config,
}: {
  method: string;
  url: string;
  data: any;
  config: any;
}) => {
  method = method.toLowerCase();
  if (method === 'post') {
    return instance.post(url, data, { ...config });
  } else if (method === 'get') {
    return instance.get(url, {
      params: data,
      ...config,
    });
  } else if (method === 'delete') {
    return instance.delete(url, {
      data,
      ...config,
    });
  } else if (method === 'put') {
    return instance.put(url, data, { ...config });
  } else {
    console.error('unknown method' + method);
    return false;
  }
};
export default axios;
