import axios from '../http/request';
// import { CSRF_TOKEN } from '@/store/mutation-types';
// import { storage } from '../storage';

export function login(data: any): any {
  return axios({
    url: `/users/session`,
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
  // return axios({
  //   url: `/users/session`,
  //   method: 'post',
  //   data,
  //   config: {
  //     headers: {},
  //     timeout: 10000,
  //   },
  // });
}

export function logout(): any {
  return axios({
    url: `/users/session`,
    method: 'delete',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getStats(): any {
  return axios({
    url: `/stats`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function register(data: any): any {
  return axios({
    // url: '/api/rest-auth/registration/',
    url: '/users',
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function putUserInfo(data: any): any {
  return axios({
    url: `/users/${data.id}`,
    method: 'put',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function passwordReset(data: any): any {
  return axios({
    url: `/users/forgot`,
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function passwordResetConfirm(data: any): any {
  return axios({
    url: `/users/reset`,
    method: 'put',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// get
export function getCurrentUser(data?: any): any {
  return axios({
    url: '/users/session',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getUserById(id: number): any {
  return axios({
    url: `/users/${id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getTrainerById(id: number): any {
  return axios({
    url: `/trainers/${id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getTrainerFavoriteWorkoutsById(id: number): any {
  return axios({
    url: `/trainers/${id}/favorite-workouts`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getUserMembershipInfoById(id: number): any {
  return axios({
    url: `/users/${id}/membership`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getQuizById(id: number): any {
  return axios({
    url: `/quiz/${id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function saveQuizAnswers(quizId, data: any): any {
  return axios({
    url: `/quiz/${quizId}/answers`,
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function createOnDemandProgress(data: any): any {
  return axios({
    url: `/ondemand/${data.id}/progress`,
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function updateOnDemandProgress(data: any): any {
  return axios({
    url: `/ondemand/${data.id}/progress/${data.progressId}`,
    method: 'put',
    data: {
      completedAt: data.completedAt,
    },
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function recommendByQuiz(): any {
  return axios({
    url: `/schedules/recommend-by-quiz`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getTimeslots(id: number): any {
  return axios({
    url: `/workouts/${id}/available-timeslots`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getWorkoutById(id: number): any {
  return axios({
    url: `/workouts/${id}/details`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getOnDemandWorkouts(data?: any): any {
  return axios({
    url: '/ondemand',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getOnDemandDetail(id: number): any {
  return axios({
    url: `/ondemand/${id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function fetchWorkouts(data?: any): any {
  return axios({
    url: '/api/mental_workouts/',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// requests a schedule for a given workout
export function requestSchedule(data?: any): any {
  return axios({
    url: '/bookings/requested-workout',
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// gets the list of workouts available to request schedules for
export function getRequestedWorkouts(): any {
  return axios({
    url: '/workouts/requested-workouts',
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getWorkouts(data?: any): any {
  return axios({
    url: '/workouts',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getSchedule(data?: any): any {
  return axios({
    url: '/schedules',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getScheduleDetail(id: number): any {
  return axios({
    url: `/schedules/${id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getScheduleRecommend(data?: any): any {
  return axios({
    url: '/schedules/recommend',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function fetchRecommendedWorkouts(data?: any): any {
  return axios({
    url: '/api/recommended_workouts/',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getBookings(data?: any): any {
  return axios({
    url: '/bookings',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getWorkoutHistory(data?: any): any {
  return axios({
    url: '/history-workouts',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function postBookings(data?: any): any {
  return axios({
    url: '/bookings',
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}
export function deleteBookings(id): any {
  return axios({
    url: `/bookings/${id}`,
    method: 'delete',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getBookingsById({ id, ...data }): any {
  return axios({
    url: `/bookings/${id}`,
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getBookings1(data?: any): any {
  const { slug, ...rest } = data;
  return axios({
    url: '/api/scheduled_workouts/' + slug + '/booking/',
    method: 'post',
    data: rest,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// export function passwordResetConfirm(data: any): any {
//   return axios({
//     url: `/api/rest-auth/password-reset-confirm/${data.uid}/${data.tokendata.token}/`,
//     method: 'post',
//     data,
//     config: {
//       headers: {},
//       timeout: 10000,
//     },
//   });
// }

// get
export function getProgress(data?: any): any {
  return axios({
    url: '/api/progress/',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getS3Token(data?: any): any {
  return axios({
    url: '/users/token',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getProducts(data?: any): any {
  return axios({
    url: '/products',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function createdOrder(data?: any): any {
  return axios({
    url: '/orders',
    method: 'post',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getOrderByUUID(uuid: string): any {
  return axios({
    url: `/orders/${uuid}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

/**
 *
 * @param data type: uuid, userId, id
 */
export function getOrdersByUserIdOrUUID(data: any): any {
  return axios({
    url: `/orders/?${data.type}=${data.id}`,
    method: 'get',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// cancel order
export function cancelOrderByUUID(uuid: string): any {
  return axios({
    url: `/orders/${uuid}`,
    method: 'put',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// reactivate order
export function reactivateOrderByUUID(uuid: string): any {
  return axios({
    url: `/orders/${uuid}/reactivate`,
    method: 'put',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function postCodeRating({ uuid, rating }): any {
  return axios({
    url: `/ratings/booking/${uuid}/${rating}`,
    method: 'post',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

// /ratings/ondemand/{onDemandId}/{rating}
export function postOnDemandRating({ onDemandId, rating }): any {
  return axios({
    url: `/ratings/ondemand/${onDemandId}/${rating}`,
    method: 'post',
    data: {},
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getLocationsList(data?: any): any {
  return axios({
    url: '/locations',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function fetchSchedulesRecommended(data?: any): any {
  return axios({
    url: '/schedules/recommend',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}

export function getCategories(data?: any): any {
  return axios({
    url: '/categories',
    method: 'get',
    data,
    config: {
      headers: {},
      timeout: 10000,
    },
  });
}
