import type { App } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router';

const store = createPinia();

export function setupStore(app: App<Element>) {
  store.use(({ store }) => {
    store.$router = router
  })
  app.use(store);
}

export { store };
