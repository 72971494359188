<template>
  <div class="card" title-tag="title" header-tag="header" header-class="card-header" body-class="card-body"
    footer-tag="footer" footer-class="card-footer" v-bind:class="{ scrolled: props.scrolled }">
    <div class="card-body">
      <div class="user-profile w-100 text-start">
        <div class="module-nav">
          <router-link class="user-profile-item" to="/my-workouts"><span>My activity</span></router-link>
          <router-link class="user-profile-item" to="/book-workout"><span>Live schedule</span></router-link>
          <router-link class="user-profile-item" to="/on-demand"><span>On Demand</span></router-link>
          <router-link class="user-profile-item" to="/all-workouts"><span>All Workouts</span></router-link>

          <div class="divider"></div>
        </div>
        <div class="module-profile">
          <router-link class="user-profile-item" to="/profile"><span>My profile</span></router-link>
          <div class="divider"></div>
          <router-link class="user-profile-item" to="/how-does-it-work"><span>How does it work?</span></router-link>
          <router-link class="user-profile-item" to="/FAQ"><span>FAQ</span></router-link>
          <div class="divider"></div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-danger logout-btn" @click="logout">Logout</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/modules/user';

const props = defineProps(['profileContracted', 'scrolled']);
const userStore = useUserStore();

const logout = async () => {
  await userStore.logout(true);
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 4px;
  background-color: rgba(0, 33, 158, 0.9);
  color: white;
  width: 290px;
  position: fixed;
  right: 0;
  top: calc(env(safe-area-inset-top) + 62px);
  border-radius: 0;
  border: none;
  z-index: 99;
}

.card.show {
  display: inherit;
}

.divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin: 10px 0;
}

.card-body {
  display: inline-flex;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 22px;
  padding-right: 22px;

  .user-profile {
    [class|='module'] {
      display: flex;
      flex-direction: column;
    }

    .module-profile,
    .module-nav {
      .user-profile-item {
        font-family: SharpGroteskSm;
        color: #fff;
        text-decoration: none;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        padding: 12px 0;

        span {
          position: relative;
        }

        &:hover,
        &.router-link-active {
          font-family: SharpGroteskSm;
          font-weight: bold;
          font-size: 16px;

          & span {
            transform: translateY(-5px);

            &::after {
              content: ' ';
              display: inline-block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 4px;
              transform: translateY(0.5rem);
              background-color: rgba(255, 201, 24, 1);
            }
          }
        }
      }
    }

    .module-nav {
      display: none;

      .user-profile-item {
        &:first-child {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.card-footer {
  display: inline-flex;
  padding: 0 22px 34px;

  .logout-btn {
    width: 93px;
    height: 36px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #FF4611;
    border-radius: 15px;
  }
}

.card-body {
  // flex-shrink: 10;
  flex: unset;
}

.card {
  transition: all 0.25s;
}

@media (max-width: 992px) {
  .card {

  }


}

@media (max-width: 840px) {
  .card {
    top: calc(72px + env(safe-area-inset-top));
    height: 100vh;
    width: 100%;
    margin-right: 0;

    .card-body {
      padding: 0px;

      .user-profile {
        padding: 18px 58px 0 70px;

        .module-nav {
          display: flex;
        }
      }
    }

    .card-footer {
      padding: 20px 58px 0 70px;
    }
  }

  .scrolled.profile-contracted {
    &.card {
      top: 10px;
    }
  }
}
</style>
