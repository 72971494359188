import type { App } from 'vue'
import { constantRouterMap } from './routes'
import { createRouter, createWebHistory, RouterScrollBehavior } from 'vue-router'
import { createRouterGuards } from './router-guards';

const routerScrollBehavior: RouterScrollBehavior = (_to, _from, position) => {
  if (_to.hash) {
    return {
      el: _to.hash,
      behavior: 'smooth',
      top: 100
    };
  }
  if (position) {
    return position;
  } else {
    return { left: 0, top: 0 };
  }
};

// 实例化路由
const router = createRouter({
  history: createWebHistory(),
  routes: constantRouterMap,
  scrollBehavior: routerScrollBehavior,
});

export function setupRouter(app: App<Element>) {
  app.use(router);

  createRouterGuards(router);
}

export default router;