import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { store } from '@/store';
import {
  getSchedule,
  getBookings,
  getCurrentUser,
  getProgress,
  getWorkouts,
  login,
  register,
} from '@/common/http/api';
import { storage } from '@/common/storage';
import {
  ACCESS_TOKEN,
  ALLOW_ANALYTICS,
  AUTHENTICATED,
  CURRENT_USER,
  PROGRESS,
  RECOMMENDED_WORKOUTS,
  COUNTRYS,
  SCHEDULE,
  // SCHEDULE_FILTER,
  OVERVIEW,
  BOOKINGS,
  PERSONAL_SCHEDULE,
  FIRST_SCHEDULES_RECOMMENDED_ID,
  MY_WORKOUTS_TAB,
} from '@/store/mutation-types';
import router from '@/router';
import { IBookings, IPersonalSchedule, IUser } from './type';
import { getCountrys } from '@/common/http/iso-3166-1';
dayjs.extend(utc);

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    bookings: [],
    progress: {
      ongoing_progress: 2,
    },
    workoutsSinceLastLevelUp: 0,
    recommendedWorkouts: [],
    products: [],
    authenticated: false,
    workouts: [],
    schedule: [],
    personalSchedule: [],
    overview: {},
    chosenWorkout: {},
    responseStatus: {
      status: true,
      msg: 'This is default response message',
      show: false,
      size: '',
      hideCallback: null,
    },
    comfirmStatus: {
      title: 'title',
      msg: 'This is default response message',
      show: false,
      size: '',
      okText: 'Yes',
      cancelText: 'No',
      hideCancel: false,
      okFn: null,
      cancelFn: null,
    },
    calendarStatus: {
      show: false,
      cancelFn: null,
      schedule: null,
    },
    loading: false,
    allowAnalytics: null,
    countrys: [],
    welcomePopupStatus: {
      show: false,
      callback: null,
    },
    firstSchedulesRecommendedId: 0,
  }),
  getters: {
    // test: state => state.test,
    getSchedule(): [any][] {
      return this.schedule.length ? this.schedule : storage.get(SCHEDULE);
    },
    getPersonalSchedule(): IPersonalSchedule[] {
      return (
        (this.personalSchedule.length && this.personalSchedule) ||
        storage.get(RECOMMENDED_WORKOUTS)
      );
    },
    getOverview(): object {
      return Object.keys(this.overview).length
        ? this.overview
        : storage.get(OVERVIEW);
    },
    getAuthenticated(): boolean {
      return this.authenticated;
    },
    getFirstSchedulesRecommendedId(): number {
      return this.firstSchedulesRecommendedId
        ? this.firstSchedulesRecommendedId
        : storage.get(FIRST_SCHEDULES_RECOMMENDED_ID);
    },
    getUser(): IUser {
      return this.user && Object.keys(this.user).length
        ? this.user
        : storage.get(CURRENT_USER);
    },
    getBookings(): IBookings[] {
      return (this.bookings.length && this.bookings) || storage.get(BOOKINGS);
    },
    getProgress(): object {
      return Object.keys(this.progress).length
        ? this.progress
        : storage.get(PROGRESS);
    },
    getWorkoutsSinceLastLevelUp(): number {
      return this.workoutsSinceLastLevelUp;
    },
    getChosenWorkout(): object {
      return this.chosenWorkout;
    },
    getResponseStatus(): object {
      return this.responseStatus;
    },
    getComfirmStatus(): object {
      return this.comfirmStatus;
    },
    getWelcomePopupStatus(): object {
      return this.welcomePopupStatus;
    },
    getCalendarStatus(): object {
      return this.calendarStatus;
    },
    getLoading(): boolean {
      return this.loading;
    },
    getRecommendedWorkouts(): any {
      return (
        (this.recommendedWorkouts.length && this.recommendedWorkouts) ||
        storage.get(RECOMMENDED_WORKOUTS)
      );
    },
    getProducts(): [any][] {
      return this.products;
    },
    getAllowAnalytics(): any {
      return storage.get(ALLOW_ANALYTICS) ?? this.allowAnalytics;
    },
    getCountrys(): [any][] {
      return storage.get(COUNTRYS) ?? this.countrys;
    },
  },
  actions: {
    async fetchBookings(data) {
      try {
        const response = await getBookings(data);

        if (response) {
          if (data.mode) {
            this.setPersonalSchedule(response.data);
          } else {
            this.setBookings(response.data);
          }
        }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchCountrys() {
      try {
        const response = await getCountrys();

        if (response) {
          this.setCountrys(response);
        }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setPersonalSchedule(data) {
      const days: any = [];
      for (let i = 0; i < data.length; i++) {
        let workout = data[i].schedule;
        // let year = moment(data[i].startedAt)
        //   .local()
        //   .startOf('year')
        //   .format('yyyy');
        let year = dayjs(data[i].startedAt)
          .local()
          .startOf('year')
          .format('yyyy');

        let existingDay = days.find((day) => {
          return dayjs(day.startedAt).format('yyyy') === year;
        });
        if (existingDay) {
          existingDay.workouts.push(workout);
        } else {
          let existingDay = { year, workouts: [workout] };
          days.push(existingDay);
        }
      }

      const ex = 7 * 24 * 60 * 60;
      storage.set(PERSONAL_SCHEDULE, days, ex);
      this.personalSchedule = days;
    },
    setWorkouts(data) {
      this.workouts = data;
    },
    setOnboardingQuizCompleted() {
      let user = this.getUser;
      user.onboardingQuizCompleted = true;
      this.setUser(user);
    },
    setSchedule(data) {
      let days: any = [];
      for (var i = 0; i < data.length; i++) {
        let workout = data[i];
        if (workout.workout) {
          // let date = moment(workout.startedAt)
          //   .local()
          //   .startOf('day')
          //   .format();
          let date = dayjs(workout.startedAt)
            .local()
            .startOf('day')
            .format('YYYY-MM-DD');
          let newDate = new Date(date);
          // console.log('newDate', newDate.toISOString());

          let existingDay: any = days.find((day) => {
            return String(day['date']).toString() === newDate.toString();
          });
          if (existingDay) {
            existingDay.workouts.push(workout);
          } else {
            let existingDay = { date: newDate, workouts: [workout] };
            // console.log('existingDay', existingDay);
            days.push(existingDay);
          }
        }
      }
      const ex = 7 * 24 * 60 * 60;
      storage.set(SCHEDULE, days, ex);
      this.schedule = days;
    },
    setOverview(data) {
      const categories = [
        ...new Set(this.workouts.map((item: any) => item.category)),
      ];
      const workout_overview = {};
      for (var i = 0; i < categories.length; i++) {
        const cat = categories[i];
        const workouts_filtered: any = this.workouts.filter((workouts: any) => {
          return workouts.category === cat;
        });
        workout_overview[cat] = [];
        for (var j = 0; j < workouts_filtered.length; j++) {
          var next_workout = data.find((scheduled_workout) => {
            return scheduled_workout.workout.id === workouts_filtered[j].id;
          });
          if (next_workout != undefined) {
            workout_overview[cat].push({
              workout: workouts_filtered[j],
              next_workout: next_workout,
            });
          } else {
            workout_overview[cat].push({
              workout: workouts_filtered[j],
              next_workout: '',
            });
          }
        }
      }
      const ex = 7 * 24 * 60 * 60;
      storage.set(OVERVIEW, workout_overview, ex);
      this.overview = workout_overview;
    },
    setAuthenticated(isAuth) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(AUTHENTICATED, isAuth, ex);
      this.authenticated = isAuth;
    },
    setUser(data) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(CURRENT_USER, data, ex);
      this.user = data;
    },
    setBookings(data) {
      let days: any = [];
      for (var i = 0; i < data.length; i++) {
        let workout = {
          bookId: data[i].id,
          ...data[i].schedule,
        };
        // let date = moment(data[i].startedAt)
        //   .local()
        //   .startOf('day')
        //   .format();
        let date = dayjs(data[i].startedAt).local().startOf('day').format();
        let newDate = new Date(date);
        let existingDay: any = days.find((day) => {
          return String(day['date']).toString() === newDate.toString();
        });
        if (existingDay) {
          existingDay.workouts.push(workout);
        } else {
          let existingDay = { date: newDate, workouts: [workout] };
          days.push(existingDay);
        }
      }
      const ex = 7 * 24 * 60 * 60;
      storage.set(BOOKINGS, days, ex);
      this.bookings = days;
    },
    setProgress(data) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(PROGRESS, data, ex);
      this.progress = data;
    },
    setRecommendedWorkouts(data) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(RECOMMENDED_WORKOUTS, data, ex);
      this.recommendedWorkouts = data;
    },
    setResponseStatus(data) {
      this.responseStatus = data;
    },
    setFirstSchedulesRecommendedId(data) {
      const ex = 7 * 24 * 60 * 60;
      storage.set(FIRST_SCHEDULES_RECOMMENDED_ID, data, ex);
      this.firstSchedulesRecommendedId = data;
    },
    setComfirmStatus(data) {
      this.comfirmStatus = data;
    },
    setWelcomePopupStatus(data) {
      this.welcomePopupStatus = data;
    },
    setCalendarStatus(data) {
      this.calendarStatus = data;
    },
    async register(userInfo: any) {
      try {
        const response = await register(userInfo);

        // const { token } = response;
        // if (token) {
        //   const ex = 7 * 24 * 60 * 60;
        //   storage.set(ACCESS_TOKEN, token, ex);
        //   this.setAuthenticated(true);
        //   this.fetchCurrentUser();
        //   this.fetchSchedule();
        //   // this.fetchRecommendedWorkouts();
        //   // this.fetchBookings();
        //   // this.fetchProgress();
        // }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async login(userInfo: any) {
      try {
        const response = await login(userInfo);

        const { token, user } = response;
        if (token) {
          const ex = 7 * 24 * 60 * 60;
          storage.set(ACCESS_TOKEN, token, ex);
          this.setAuthenticated(true);
          this.setUser(user);
        }
        return Promise.resolve(response);
      } catch (e) {
        let rS = {
          status: 'Failed',
          msg: 'Email/password combination not recognised.',
          show: true,
        };
        this.setResponseStatus(rS);
        return Promise.reject(e);
      }
    },
    async logout(goToLogin: boolean = false) {
      storage.clearCookie();
      storage.remove(ACCESS_TOKEN);
      storage.remove(MY_WORKOUTS_TAB);
      // storage.remove(SCHEDULE_FILTER);
      window.sessionStorage.removeItem('firstJump');
      this.setAuthenticated(false);
      this.setUser({});
      this.setBookings([]);
      this.setProgress({
        ongoing_progress: 0,
      });
      this.setRecommendedWorkouts([]);
      // this.setSchedule([]);
      this.setOverview({});

      goToLogin && router.push('/login');

      // if (goToLogin) {
      //   router.push('/login');
      //   window.location.reload();
      // }
    },
    async fetchCurrentUser() {
      try {
        this.setAuthenticated(true);
        const data = await getCurrentUser();
        if (data) {
          this.setUser(data);
          this.setAuthenticated(true);
          const isFirstJump = window.sessionStorage.getItem('firstJump');
          if (!data.completedWorkouts && isFirstJump !== 'false') {
            const firstId = storage.get(FIRST_SCHEDULES_RECOMMENDED_ID);
            if (firstId) {
              this.setWelcomePopupStatus({
                show: true,
                callback: (id) => {
                  router.push(`/workout-detail/${id}`);
                },
              });
            }
          }
        }
        return Promise.resolve(data);
      } catch (e) {
        console.log('[fetchCurrentUser:error]', e);
        this.logout(true);
        return Promise.reject(e);
      }
    },
    async fetchProgress() {
      try {
        const response = await getProgress();
        console.log(response);
        const data = response;
        if (data) {
          this.setProgress(data);
          // this.fetchRecommendedWorkouts();
        }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchWorkouts() {
      try {
        const response = await getWorkouts();
        // const response = await Promise.resolve([]);
        console.log(response);
        const data = response;
        if (data) {
          this.setWorkouts(data);
        }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchSchedule(params) {
      try {
        const response = await getSchedule(params);
        const { data } = response;
        if (data) {
          this.setSchedule(data);
          this.setOverview(data);
        }
        return Promise.resolve(response);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    setChosenWorkout(data) {
      this.chosenWorkout = data;
    },
    setAllowAnalytics(data) {
      this.allowAnalytics = data;
      storage.set(ALLOW_ANALYTICS, data, null);
    },
    setCountrys(data) {
      this.countrys = data;
      const ex = 7 * 24 * 60 * 60;
      storage.set(COUNTRYS, data, ex);
    },
  },
});

// Need to be used outside the setup
export function useUserStoreWidthOut() {
  return useUserStore(store);
}
