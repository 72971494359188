<template>
  <div
    class="modal fade show"
    id="response-modal"
    tabindex="-1"
    aria-labelledby="response-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" :class="[`${responseStatus.size ?? ''}`]">
    <!-- 'modal-small'/'modal-lg'/'modal-xl' -->
      <div
        class="modal-content response-modal-content"
        :class="{
          [responseStatus.status]: true,
        }"
      >
        <div class="modal-header response-modal-header">
          <h3 class="response-modal-title" v-if="responseStatus.status">
            {{ responseStatus.status }}
          </h3>
          <h3 class="response-modal-title" v-else>Failed!</h3>
          <button class="close-modal-btn" size="md" @click="hide">×</button>
        </div>
        <div class="modal-body">
          {{ responseStatus.msg }}
          <br />
          <br />
          <p v-if="!responseStatus.status">
            If the problem persists, please contact the support!
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-if="responseStatus.show" class="modal-backdrop fade show"></div> -->
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/modules/user';
import * as bootstrap from 'bootstrap';
import { ref, computed, onMounted, watch } from 'vue';

const userStore = useUserStore();
const myModal: any = ref(null);

const responseStatus: any = computed(() => {
  return userStore.getResponseStatus;
});

watch(
  () => responseStatus.value,
  (value: any) => {
    if (value.show) {
      show();
    } else {
      hide();
    }
  },
  {
    deep: true,
  }
);

const show = () => {
  myModal.value.show();
};
const hide = () => {
  if (responseStatus.value.show) {
    myModal.value.hide();
  }
};

onMounted(() => {
  const el = document.getElementById('response-modal');
  myModal.value = new bootstrap.Modal(el as HTMLElement);

  el?.addEventListener('hidden.bs.modal', function () {
    console.log('[hidden.bs.modal]');
    let callback = responseStatus.value.hideCallback;
    userStore.setResponseStatus({
      status: true,
      msg: 'This is default response message',
      show: false,
      size: '',
      hideCallback: null
    });
  
    if (callback && typeof callback === 'function') {
      callback();
    }
  })
});
</script>

<style lang="scss" scoped>
.modal-small {
  width: 316px;
}
.modal-body {
  padding: 0 0 0 16px;
  letter-spacing: -0.02em;
}
.response-modal-content {
  border-radius: 0;
  border: none;
  padding: 1.2vw;
  font-family: SharpGrotesk;
  font-size: 16px;
  // font-size: clamp(20px, 1.5vw, 26px);
}

.response-modal-content.Success {
  background-color: #C5F5E9;
}

.response-modal-content.Failed {
  background-color: #FFDACF;
}

.response-modal-header {
  font-family: SharpGroteskSm;
  font-weight: bold;
  border-bottom: none;
}
.response-modal-title {
  font-family: SharpGroteskSm;
  font-weight: bold;
  border-bottom: none;
  font-size: 20px;
  // font-size: clamp(20px, 2vw, 40px);
  color: #00219c;
  margin-bottom: 0;
}
.close-modal-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: #00219E;
  border: none;
  color: #fff;
  font-size: 25px;
  // padding: 5px 15px;
  position: absolute;
  right: -16px;
  top: -16px;
  border-radius: 50%;
}
// .close-modal-btn:hover {
//   background-color: transparent;
//   border: none;
//   color: #00219c;
// }
@media (max-width: 576px) {
  .modal-dialog {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
