// import './styles/tailwind.css';
import { createApp } from 'vue';
// import VueSmoothScroll from 'v-smooth-scroll';
import VueTippy from 'vue-tippy';
import VueGtag from 'vue-gtag';
import VueResizeText from "vue3-resize-text"
// import 'virtual:fonts.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'virtual:svg-icons-register';

import App from './App.vue';
import router, { setupRouter } from '@/router';
import { setupStore } from '@/store';
// import { setupNaive } from '@/plugins/naive';
import { useUserStoreWidthOut } from './store/modules/user';
import { setupCustomComponents } from '@/plugins/customComponents';
import VueSocialSharing from 'vue-social-sharing';
import VueCropper from "vue-cropper";
import * as Sentry from "@sentry/vue";

import 'vue-cropper/dist/index.css'

const userStore = useUserStoreWidthOut();

async function bootstrap() {
  // userStore.fetchWorkouts();
  userStore.fetchSchedule({
    available: true
  });
  userStore.fetchCountrys();

  const app = createApp(App);
  // app.use(VueSmoothScroll, {
  //   duration: 500,
  //   updateHistory: false,
  // });
  app.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'bottom-start',
      allowHTML: true,
      // trigger: 'click',
      theme: 'tomato',
      maxWidth: 186
    }, // => Global default options * see all props
  });
  app.use(VueSocialSharing);
  app.use(
    VueGtag,
    {
      config: { id: import.meta.env.VITE_GLOB_GOOGLE_ANALYTICS_CODE },
      bootstrap: false,
    },
    router
  );
  app.use(VueCropper);
  app.use(VueResizeText)

  setupCustomComponents(app);
  setupRouter(app);
  // setupNaive(app);
  setupStore(app);


  Sentry.init({
    app: app,
    dsn: "https://e3043f92ce91e10700e57fde1f2e827a@o4506560202276864.ingest.sentry.io/4506560220299264",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/api\.upotential\.se\//, /^https:\/\/api-staging\.upotential\.se\//],
        tracePropagationTargets: ["localhost"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


  app.mount('#app');
}

bootstrap();
