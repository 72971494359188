// import { useUserStoreWidthOut } from '@/store/modules/user';
// import { ACCESS_TOKEN } from '@/store/mutation-types';
import { storage } from '@/common/storage';
// import { PageEnum } from '@/enums/pageEnum';
import { PageEnum } from '@/enums/pageEnum';
import { useUserStoreWidthOut } from '@/store/modules/user';
import { AUTHENTICATED } from '@/store/mutation-types';
import { Router } from 'vue-router';

const BASE_WELCOME_HOME = PageEnum.BASE_WELCOME_HOME;
const LOGIN_PATH = PageEnum.BASE_LOGIN;
const FORGOT_PASSWORD = PageEnum.FORGOT_PASSWORD;
const ABOUT_US = PageEnum.ABOUT_US;
const FAQ = PageEnum.FAQ;
const PRIVACY_POLICY = PageEnum.PRIVACY_POLICY;
const TERMS_OF_SERVICE = PageEnum.TERMS_OF_SERVICE;
const RESET_PASSWORD = PageEnum.RESET_PASSWORD;
const SIGN_UP = PageEnum.SIGN_UP;
const COMPANIES = PageEnum.COMPANIES;
const MENTAL_FITNESS = PageEnum.MENTAL_FITNESS;
const CAREERS = PageEnum.CAREERS;
const WAITING_LIST = PageEnum.WAITING_LIST;
const PRICING = PageEnum.PRICING;

const whitePathList: string[] = [
  BASE_WELCOME_HOME,
  LOGIN_PATH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ABOUT_US,
  FAQ,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  SIGN_UP,
  COMPANIES,
  MENTAL_FITNESS,
  CAREERS,
  WAITING_LIST,
  PRICING,
]; // no redirect whitelist

export function createRouterGuards(router: Router) {
  const userStore = useUserStoreWidthOut();

  router.beforeEach(async (to, _from, next) => {
    // console.log(to.fullPath, from.fullPath);
    // console.log(to);

    // test data start
    // userStore.setRecommendedWorkouts(rw);
    // test data end

    const title = to.meta.title;
    if (title) {
      document.title = title + ' - Upotential';
    }else{
      document.title = 'Upotential - The Gym for Mental Fitness';
    }

    let isAuth = storage.get(AUTHENTICATED);
    let found = router.options.routes.find((x) => {
      return to.name === x.name;
    });

    if (to.name === "schedule-request" || to.name === 'workout' || to.name === 'workout-detail' || to.name === 'rate-workout' || to.name == 'on-demand-detail') {
      next();
    }
    if (to.name === 'join' && !isAuth) {
      next();
    }
    if (!whitePathList.includes(to.path) && !isAuth) {
      next({ name: PageEnum.BASE_LOGIN_NAME });
    }

    if (to.name === 'quiz') {

      if (_from.name === 'workout-detail') {
        next({ name: 'book-workout' });
        return;
      }

      // const onboardingQuizCompleted = userStore.getUser?.onboardingQuizCompleted;
      // if (onboardingQuizCompleted) {
      //   next({ name: 'book-workout' });
      // } else {
      //   next();
      // }
    }

    if (isAuth) {
      if(!userStore.getAuthenticated){
        try {
          await userStore.fetchCurrentUser();
        } catch (err) {
          console.log('[fetchCurrentUser]', err);
          next();
          return;
        }
      }
    }
    if (
      (to.name === 'login' || to.name === 'home' || to.name === 'signup' || to.name === 'join') &&
      isAuth
    ) {
      next(PageEnum.BASE_HOME);
    } else if (!found) {
      next({ name: PageEnum.ERROR_PAGE_NAME });
    } else {
      next();
    }
  });

  router.onError((error) => {
    console.log(error, 'router error');
  });
}
