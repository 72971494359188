import { RouteRecordRaw } from 'vue-router';
// import BaseLayout from '@/layout/BaseLayout.vue';
// import BlankLayout from '@/layout/BlankLayout.vue';

export const constantRouterMap: RouteRecordRaw[] = [
  // {
  //   path: '/home',
  //   name: 'login',
  //   component: () => import('@/views/Login.vue'),
  // },
  {
    path: '/profile/',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/view-profile/:id',
    name: 'view-profile',
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/trainer/:id',
    name: 'trainer',
    component: () => import('@/views/Trainer.vue'),
  },
  {
    path: '/profile/',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
  },
  {
    path: '/profile-edit',
    name: 'profile-edit',
    component: () => import('@/views/ProfileEdit.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/my-workouts',
    name: 'my-workout',
    component: () => import('@/views/MyWorkout.vue'),
    meta: {
      title: 'My Workouts',
      // keepAlive: true,
    },
  },
  {
    path: '/book-workout',
    name: 'book-workout',
    component: () => import('@/views/BookWorkout.vue'),
    meta: {
      title: 'Book Workout',
      keepAlive: true,
    }
  },
  {
    path: '/all-workouts',
    name: 'all-workouts',
    component: () => import('@/views/AllWorkouts.vue'),
    meta: {
      title: 'All Workouts',
      keepAlive: true,
    }
  },
  {
    path: '/on-demand',
    name: 'on-demand',
    component: () => import('@/views/OnDemand.vue'),
    meta: {
      title: 'On Demand Workouts',
      keepAlive: true,
    }
  },
  {
    path: '/on-demand-detail/:id',
    name: 'on-demand-detail',
    component: () => import('@/views/OnDemandDetail.vue'),
    meta: {
      title: 'On Demand Workout Detail',
      keepAlive: false,
    }
  },
  {
    path: '/workout-detail/:id',
    name: 'workout-detail',
    component: () => import('@/views/WorkoutDetail.vue'),
  },
  {
    path: '/workout/:id',
    name: 'workout',
    component: () => import('@/views/Workout.vue'),
  },
  {
    path: '/schedule-request/:id',
    name: 'schedule-request',
    component: () => import('@/views/ScheduleRequest.vue'),
  },
  // {
  //   path: '/profile/manage',
  //   name: 'profile',
  //   component: ProfileManagement,
  // },
  // {
  //   path: '/store',
  //   name: 'store',
  //   component: Store,
  // },
  // {
  //   path: '/store/purchase/:slug',
  //   name: 'store',
  //   component: Store,
  // },
  {
    path: '/join/:code',
    name: 'join',
    component: () => import('@/views/SignUp.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/waiting-list',
    name: 'waiting-list',
    component: () => import('@/views/WaitingList.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/WaitingList.vue'),
  },
  {
    path: '/forgotpwd',
    name: 'forgotpwd',
    component: () => import('@/views/Forgotpwd.vue'),
  },
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('@/views/PasswordResetConfirm.vue'),
  },
  {
    path: '/quiz/:quizId?',
    name: 'quiz',
    component: () => import('@/views/Quiz.vue'),
  },
  {
    path: '/FAQ1',
    name: 'faq1',
    component: () => import('@/views/FAQ.vue'),
  },
  {
    path: '/FAQ',
    name: 'faq',
    component: () => import('@/views/FAQ1.vue'),
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/AboutUs.vue'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/Pricing.vue'),
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import('@/views/Careers.vue'),
  },
  {
    path: '/password-reset-confirm/:uid/:token',
    name: 'password-reset-confirm',
    component: () => import('@/views/PasswordResetConfirm.vue'),
  },
  {
    path: '/rate-workout/:code/:rating',
    name: 'rate-workout',
    component: () => import('@/views/RateWorkout.vue'),
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/Companies.vue'),
  },
  {
    path: '/mental-fitness',
    name: 'mental-fitness',
    component: () => import('@/views/MentalFitness.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('@/views/TermsOfService.vue'),
  },
  {
    path: '/how-does-it-work',
    name: 'how-does-it-work',
    component: () => import('@/views/HowDoesItWork.vue'),
  },
  // /:pathMatch(.*)
  {
    name: 'NOT_FOUND',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/PageNotFound.vue'),
  },
];

// 白名单(路径)

// export const whiteList = ['/login', '/403', '/404', '/500'];
