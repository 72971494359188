import iso from 'iso-3166-1';

export function getCountrys() {
  const allCountrys = iso.all();

  allCountrys.forEach((country) => {
    if (country.country === 'Taiwan, Province of China') {
      country.country = 'Taiwan';
    }
    if (country.country === 'United Kingdom of Great Britain and Northern Ireland') {
      country.country = 'United Kingdom';
    }
    if (country.country === 'United States of America') {
      country.country = 'United States';
    }
  });

  return Promise.resolve(allCountrys);
}

export function getCountryByAlpha(alpha, type = 3) {
  const country = iso[`whereAlpha${type}`](alpha);
  
  if (country && country.country === 'Taiwan, Province of China') {
    country.country = 'Taiwan';
  }
  if (country && country.country === 'United Kingdom of Great Britain and Northern Ireland') {
    country.country = 'United Kingdom';
  }
  if (country && country.country === 'United States of America') {
    country.country = 'United States';
  }
  return country;
}