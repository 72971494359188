export const ACCESS_TOKEN = 'ACCESS-TOKEN'; // usertoken
export const CSRF_TOKEN = 'csrftoken'; // user csrf token
export const CURRENT_USER = 'CURRENT-USER'; // current user
export const TABS_ROUTES = 'TABS-ROUTES'; // Tabs
export const AUTHENTICATED = 'AUTHENTICATED'; // AUTHENTICATED
export const RECOMMENDED_WORKOUTS = 'RECOMMENDED-WORKOUTS'; // RECOMMENDED_WORKOUTS
export const ALLOW_ANALYTICS = 'ALLOW-ANALYTICS'; // RECOMMENDED_WORKOUTS
export const PROGRESS = 'PROGRESS'; // PROGRESS
export const COUNTRYS = 'COUNTRYS'; // COUNTRYS
export const SCHEDULE = 'SCHEDULE'; // SCHEDULE
export const OVERVIEW = 'OVERVIEW'; // OVERVIEW
export const BOOKINGS = 'BOOKINGS'; // BOOKINGS
export const PERSONAL_SCHEDULE = 'PERSONAL_SCHEDULE'; // PERSONAL_SCHEDULE
export const SCHEDULE_FILTER = 'SCHEDULE-FILTER';
export const MY_WORKOUTS_TAB = 'MY_WORKOUTS_TAB';
export const FIRST_SCHEDULES_RECOMMENDED_ID = 'FIRST_SCHEDULES_RECOMMENDED_ID';
